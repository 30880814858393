import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 국내외 업계 선도 클라우드 기업 대상 RFI 발송… 인프라 투자 구체적 행보",
  "| 기술 기반으로 비용 효율화 하여 소비자에게 혜택 돌아갈 수 있도록 할 것",
]

const content = () => (
  <div>
    <br />

    <p>
      스테이지엑스(대표 서상원)는 본격적으로 클라우드 코어망 인프라 구축을 진행하기 위해, 최근 업계 내 경쟁력 있는 국내외 클라우드
      기업들을 대상으로 RFI (Request For Information)를 발송했다고 26일 밝혔다.
      <br />
      <br />
      그동안 스테이지엑스는 코어망을 클라우드 기반으로 구축하겠다는 계획을 지속해서 밝혀왔다. 클라우드 기술은 가트너 등을 통해 5G
      통신 네트워크를 혁신할 수 있는 미래기술 방법론으로 여러차례 제시된 바 있다. 하드웨어에 의존하는 방식은 변경이나 확장이
      어렵고, 비용이 많이 든다는 단점이 있기 때문이다. 비용적 효율 측면 외에도 클라우드 기반 네트워크 구축은 가용성과 확장성,
      안전성이 높다는 측면에서 일반적인 장점을 가진다. 특히, 데이터센터 화재와 같은 통신 장애발생 시 빠른 대응이 가능하다. 때문에
      글로벌 통신사들과 미 국방부 등 주요 기관들에서도 서비스 구축 시 클라우드 형태를 전격적으로 채택하여 적용하고 있다.
      <br />
      <br />
      스테이지엑스는 전용 하드웨어 기반의 통신설비를 필수적으로 구축해야 했었던 기존과 달리, 시작 단계에서부터 클라우드 기반
      네트워크를 구축하여 효율적인 운영을 추구할 계획이다. 구체적으로, 스테이지엑스는 '클라우드 네이티브 네트워크 기능(CNF)'을
      적용하여 기존 '가상 네트워크 기능(VNF)'과 융합된 하이브리드형 클라우드 기술을 도입할 예정이다. 이를 통해 디지털 비즈니스
      환경에서의 사업적, 기술적 경쟁력을 향상시킬 수 있다. 풀MVNO로 성장할 사업적 비전을 가지고 있는 스테이지파이브와도 협업을
      통해 해당사의 코어망 구축을 조속히 추진할 예정이다.
      <br />
      <br />
      이번 RFI 발송은 이를 위한 첫 걸음이며, 업계를 선도하는 국내외 클라우드 기업들을 대상으로 발송되었다.
      <br />
      <br />
      스테이지엑스 관계자는 “클라우드 코어망의 개방성을 통해 AI와 빅데이터 등을 유연하게 수용하고, 이를 기반으로 6G 시대의
      네트워크 기술 개발을 대비한다는 것이 스테이지엑스의 기술 비전 중 하나”라며, “국내외 최고의 클라우드 전문기업과 협업하여
      클라우드 기반 통신환경을 구축하는 것은 디지털 환경에 대처하는 바람직한 방향이며, 해당 과정을 통해 절감한 비용이 고객께
      혜택으로 돌아갈 수 있도록 최선을 다할 예정”이라고 밝혔다.
      <br />
      <br />
    </p>
  </div>
)

const press51 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2024년 03월 27일 즉시배포 가능"
      pageInfo=""
      title="스테이지엑스, 클라우드 코어망 인프라 구축 위한 업체선정 나서"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press51
